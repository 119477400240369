<div class="modal">
    @if (args.isModal != false) {
        <div class="titlebar">
            <img [src]="args.icon"/>
            <h3
                #titleBar
                [title]="args.tooltip"
                [innerHtml]="args.title"
                [class.overflowing]="overflowTitle(titleBar)"
            ></h3>

            <div class="toolbar">
                @for (toggle of toggles; track toggle) {
                    <button
                        mat-icon-button
                        [title]="toggle.title"
                        [class.selected]="panelMode == toggle.id && panelOpen"
                        (click)="onPanelOpenChange(toggle.id)"
                    >
                    @if (toggle.icon == 'article') {
                        <mat-icon [fontIcon]="toggle.icon" aria-hidden="false"></mat-icon>
                    }
                    @else {
                        <mat-icon [svgIcon]="toggle.icon" aria-hidden="false"></mat-icon>
                    }
                    </button>
                }
            </div>

            <button
                mat-icon-button
                (click)="onClose()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
    }
    <div class="content e-dlg-content">
        @if ((!toggles || toggles.length == 0) && args) {
            <ngx-lazy-loader
                [component]="args.id"
                [group]="args.group || 'default'"
                [inputs]="args.inputs"
                [outputs]="args.outputs"
            />
        }

        @if (toggles?.length > 0) {
            <ejs-splitter
                #dialogSplitter
                separatorSize="4"
                width="100%"
                style="border: 0;"
            >
                <e-panes>
                    <e-pane size="75%" min="250px" [collapsible]="false">
                        <ng-template #content>
                            @if (args) {
                                <ngx-lazy-loader
                                    [component]="args.id"
                                    [group]="args.group || 'default'"
                                    [inputs]="args.inputs"
                                    [outputs]="args.outputs"
                                    (componentLoaded)="componentInstance = $event"
                                />
                            }
                        </ng-template>
                    </e-pane>
                    <e-pane size="25%" min="250px" [collapsible]="true" [collapsed]="!panelOpen">
                        <ng-template #content>
                            @switch (panelMode) {
                                @case ("comments") {
                                    <app-notes
                                        [asset]="asset"
                                    />
                                }
                                @case ("doc") {
                                    <app-asset-documentation
                                        [asset]="asset"
                                    />
                                }
                                @case ("tags") {
                                    <app-property-sheet
                                        [asset]="asset"
                                    />
                                }
                                @case ("topics") {
                                    <app-topics
                                        [asset]="asset"
                                        [dialog]="dialogService"
                                    />
                                }
                            }
                        </ng-template>
                    </e-pane>
                </e-panes>
            </ejs-splitter>
        }
    </div>
</div>
