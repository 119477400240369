<div class="titlebar">
    <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<ngx-lazy-loader
    [component]="args.id"
    [inputs]="args.inputs"
    [outputs]="args.outputs"
/>
