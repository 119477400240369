import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { LazyLoaderComponent } from 'src/app/components/lazy-loader/lazy-loader.component';

@Component({
    selector: 'app-dialog-wrapper-basic',
    templateUrl: './dialog-wrapper-basic.component.html',
    styleUrls: ['./dialog-wrapper-basic.component.scss'],
    imports: [
        LazyLoaderComponent,
        MatButtonModule,
        MatIconModule,
    ],
    standalone: true
})
export class DialogWrapperBasicComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public args,
        public dialog: DialogRef
    ) {
    }

    onClose() {
        this.dialog.close();
    }
}

